<template>
  <div id="staffDash">
    <div id="leftNav">
        <button class="nb"  v-on:click="num = 3">Basic Registration</button>
        <button class="nb" v-on:click="num = 1;populateUserEmails();populateBookingTypes()">Create Booking</button>
        <button class="nb" v-on:click="num = 2;populateBookingTypes();populateUserEmails()">Manage Bookings</button>
        <button class="nb" v-on:click="num = 4">Manage Stock</button>
        <button class="nb" v-on:click="num = 6">Edit Client</button>
        <button class="nb" v-on:click="num = 5">Create Booking Type</button>
        <button class="nb" v-on:click="num = 8">Manage Booking Types</button>
        <button class="nb" v-on:click="num = 9;populateClientBookings()">Scheduler</button>
        <button class="nb" v-on:click="num = 10">Client Profiles</button>
        <button class="nb" v-on:click="num = 11;getUsersForSearch()">Notifications</button>
        <button class="nb" v-on:click="num = 7;populateAvailableSurveys()">View Survey Response</button>
    </div>

    <div id="toolbox">
        <!-- Create Booking Type-->
<div id="Booking Type Creation Form" v-if="num === 5">    
        <label>Booking Type Name</label>
        <input v-model="newTypeName" placeholder="Must Be Unique">
        <label>Booking Price</label>
        <input v-model="BookingPrice" placeholder="420.99">
        <div id="actDef">
        <label>Booking Activities</label>
        <button id="AddActivity" v-on:click="addField()">+</button>
        </div>
        <div id="actAdd" v-for="(activity, i) in acts" :key="i">
          <input v-model="acts[i].activity" :name="'ActField'+i.toString()" placeholder="New Activity">
          <button id="RemoveActivity" v-if="i>=1" v-on:click="removeField(i.toString())">X</button>
        </div>
        <button v-on:click="CreateBookingType()">Create Booking Type</button>
    </div> 
        <!--Notification System-->
<div id="Notifiction Form" v-if="num === 11">    
        <label>Search Recipient</label>
        <label>Search by first name</label>
        <input placeholder="Enter users first name" @input="updateSearch($event)"/>
        <label>Select Recipient</label>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,index) in this.filteredUsers" :key="row">
              <td>{{row.userID}}</td>
              <td>{{row.username}}</td>
              <td>{{row.first_name}}</td>
              <td>{{row.last_name}}</td>
              <td><input type="checkbox" id="checkbox" v-model="row.checkedF" v-on:change="setSelectedRecipient(index,row.checkedF,'filtered')"/></td>
            </tr>
          </tbody>
        </table>
        <label>Selected Recipients</label>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,index) in this.selectedRecipients" :key="row">
              <td>{{row.userID}}</td>
              <td>{{row.username}}</td>
              <td>{{row.first_name}}</td>
              <td>{{row.last_name}}</td>
              <td><input type="checkbox" id="checkbox" :checked="true" v-model="row.checked" v-on:change="setSelectedRecipient(index,row.checked,'selected')"/></td>
            </tr>
          </tbody>
        </table>
        <label>Message Title</label>
        <input v-model="messageTitle" placeholder="Message Title">
        <label>Message Contents</label>
        <textarea v-model="fullMessage" placeholder="Type message here"></textarea>
        <button id="toolboxButton" v-on:click="submitNotification(messageTitle,fullMessage)">Broadcast Notification</button>
</div>
    <!-- Survey viewer-->
        <div id="SurveyView" v-if="num === 7">
      <select v-model="surveyList" v-on:change="surveySelect($event)">
        <option
          v-for="survey in availableSurveys"
          v-bind:key="survey"
          v-bind:survey="availableSurveys.survey"
        >
          {{ survey.survey }}
        </option>
      </select>

      <button id = "surveyButton" v-on:click="printSurvey()">Display Survey</button>
      <div id="sv">
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dataPoint, i) in dataForTable" :key="i">
              <td>{{i}}</td>
              <td>{{dataPoint}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>    
        <!-- Booking Creation-->
<div id="Booking Creation Form" v-if="num === 1">    
        <label>Client Email</label>
        <select v-model="EmailList"  v-on:change="clientSelect($event)"><option></option><option v-for="Adress in userEmails" v-bind:key="Adress.id" v-bind:Adress=userEmails.Adress >{{Adress.Adress}}</option></select>
        <label>Booking Type</label>
        <select v-model="BookingList"  v-on:change="bookingSelect($event)"><option></option><option v-for="booking in bookingTypes" v-bind:key="booking.id" v-bind:booking=bookingTypes.booking>{{booking.booking}}</option></select>
        <label>Booking Date</label>
        <div id="dateSelector">
        <Datepicker id="bookingdatepicker" v-model="bookingDatePicker"></Datepicker>
        </div>
        <button v-on:click="payment()">pay</button>
        <button id="submitButton" v-on:click="createBooking()">Create Booking</button>

    </div>   
        <!-- Functional User Registration-->
    <div id="basicReg" v-if="num === 3">    
        <label>First Name</label>
        <input v-model="firstname" placeholder="Jane">
        <label>Last Name</label>
        <input v-model="lastname" placeholder="Smith"/>
        <label>Contact Number</label>
        <input v-model="contact" placeholder="0613334442"/>
        <label>Email Adress</label>
        <input v-model="email"  placeholder="abc@def.com"/>
        <button id="submitButton" v-on:click="staffRegisterUser()">Submit</button>
    </div>  
        <!-- Manage bookings-->
    <div id="ManageBookings" v-if="num === 2">
        <div id="filterbar">
                 <Datepicker id="filterstartdate" v-model="startdate"  type="date" :enableTimePicker="false"></Datepicker>
                 <Datepicker id="filterenddate" v-model="enddate"  type="date" :enableTimePicker="false"></Datepicker>
                 <select id="filterClient" v-model="EmailList"  v-on:change="clientSelect($event)"><option></option><option v-for="Adress in userEmails" v-bind:key="Adress.id" v-bind:Adress=userEmails.Adress >{{Adress.Adress}}</option></select>
                 <select id="filterType" v-model="BookingList"  v-on:change="bookingSelect($event)"><option></option><option v-for="booking in bookingTypes" v-bind:key="booking.id" v-bind:booking=bookingTypes.booking>{{booking.booking}}</option></select>
                <button id="filterButton" v-on:click="fbButton()">Filter Bookings</button>
        </div>
              <div id="sv">
        <table>
          <thead>
            <tr>
              <th>Booking ID</th>
              <th>Client Email</th>
              <th>Booking Start Date</th>
              <th>Booking Type</th>
              <th>Payment Status</th>
              <th>Next Appointment Date</th>
              <th>Current Step</th>
              <th>Completed</th>
              <th>EDIT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,index) in bookingData" :key="row">
              <td>{{row.BookingID}}</td>
              <td>{{row.ClientUsername}}</td>
              <td>{{row.BookingStartDate}}</td>
              <td>{{row.BookingType}}</td>
              <td>{{row.PaymentStatus}}</td>
              <td>{{row.NextDate}}</td>
              <td>{{row.CurrentStep}}</td>
              <td>{{row.Completed}}</td>
              <td><button v-on:click="this.selectedBooking = [];editButton(index+1)">Edit</button></td>
            </tr>
          </tbody>
        </table>
        <div id="editor">
                <h4>Edit Booking</h4>
                <label id="fieldName">Client: </label><textarea id="fieldContents" v-model="this.selectedBooking[0]"></textarea>
                <label id="fieldName">Bookings Start Date: </label><textarea id="fieldContents" v-model="this.selectedBooking[1]"></textarea>
                <label id="fieldName">Booking Type: </label><textarea id="fieldContents" v-model="this.selectedBooking[2]"></textarea>
                <label id="fieldName">Payment Status: </label><textarea id="fieldContents" v-model="this.selectedBooking[3]"></textarea>
                <label id="fieldName">Next Appointment: </label><textarea id="fieldContents" v-model="this.selectedBooking[4]"></textarea>
                <label id="fieldName">Current Step: </label><textarea id="fieldContents" v-model="this.selectedBooking[5]"></textarea>
                <label id="fieldName">Completion Status: </label><textarea id="fieldContents" v-model="this.selectedBooking[6]"></textarea>
                <button id="removeButton" v-on:click="removeBooking()">Remove Booking</button><button id="updateButton" v-on:click="updateBooking()">Update Booking</button>
        </div>
      </div>
    </div>
        <!-- Scheduler-->
    <div id="Scheduler" v-if="num === 9">
      <div id="editSchedule">
       <label>Select Booking By ID</label> 
       <select v-model="BookingList"  v-on:change="selectClientBooking($event)"><option></option><option v-for="booking in clientBookings" v-bind:key="booking.id" v-bind:booking=bookingTypes.booking>{{booking.booking}}</option></select>          
      <label>Client</label>
      <p>{{this.schedulerBooking[0].username}}</p>
      <label>Booking Type</label>
      <p>{{this.schedulerBooking[0].type}}</p>
      <label>Date</label>
      <Datepicker v-model="scheduleDate"  type="date" :enableTimePicker="true"></Datepicker>
      <select v-model="remainingList" v-on:change="selectActivity($event)">
        <option></option><option v-for="activity in remainingActivities" v-bind:key="activity.id" v-bind:activity=remainingActivities.activity>{{activity}}</option>
        </select>
        <button v-on:click="createScheduleEntry()">Create Schedule Entry</button>
      </div>
      <div id="scheduleViewer">
        <div id="ViewingOptions">
          <Datepicker v-model="viewingDate"  type="date" :enableTimePicker="false"></Datepicker>
          <button>View From Selected Date</button>
        </div>
        <div id="cal">

        </div>
      </div>
    </div>    
        <!-- Manage Stock-->
    <div id="ManageStock" v-if="num === 4">    
        <label>Manage Stock</label>
    </div>
     <!-- Edit Client-->
    <div id="EditClient" v-if="num === 6">    
        <label>Edit Client Information</label>
    </div>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
//import 'https://js.yoco.com/sdk/v1/yoco-sdk-web.js';
export default {
beforeMount(){
this.getLoginCookie();
},
    name:'StaffDash',
    components: {
  Datepicker
},
    data(){

        return{
           // searchTerm:"",
            loggedUser:'',
            selectedRecipients:[],
            filteredUsers:[],
            allUsers:[],
            notiSearch:"",
            scheduleActivitySelected:"",
            remainingActivities:[],
            schedulerBooking:[],
            clientBookings:[],
            selectedClientbooking:"",
            newTypeName:"",
            BookingPrice:"",
            acts: [{activity:"New Activity"}],
            dataForTable: [],
            availableSurveys: [],
            responderSelected: "",
            bookingDatePicker:null,
            originalBooking:[],
            selectedBooking:[],
            bookingData:[],
            startdate:null,
            enddate:null,
            num: 3,
            email:'',
            userType:'client',
            firstname:'',
            lastname:'',
            userEmails:[],
            bookingTypes:[],
            months:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            //days:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            days:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
            contact:'',
            bookingTypeSelected:'',
            bookingClientSelected:'',
            bookingDateSelected:'',
            bookingDay:'',
            bookingMonth:'',
            bookingYear:'',
            bookingTime:'',
            bookingTimeHour:'',
            bookingTimeMinute:'',
            filterstartdate:null
        }
    },
    methods:{
      getLoginCookie(){
        let CookieContent = document.cookie;
        let processedCookie = CookieContent.substring(3).replace("%40","@");
        this.loggedUser = processedCookie;
        console.log(processedCookie);
      },
    setSelectedRecipient(index,checked,tableName){
      if(tableName.toString().includes('filtered')){
        let Recipient = this.filteredUsers[index];
        if(checked==false){
          for(let i in this.filteredUsers){
            if(this.selectedRecipients[i]==Recipient){
              this.selectedRecipients.splice(i,1);
            }
          }
        }else{
          console.log("adding user");
          this.selectedRecipients.push(Recipient);
        }
      }else{
        if(checked==false){
              this.selectedRecipients.splice(index,1);
        }else{
          console.log("already added");
          //this.selectedRecipients.push(Recipient);
        }
      }
    },
    async submitNotification(messageTitle,fullMessage){
      //title,date,recipients,createdBy,contents
      let title = messageTitle;
      let contents = fullMessage;
      let recipients = "";
      for(let row in this.selectedRecipients){
        recipients += this.selectedRecipients[row].userID +",";
      }
      let createdBy = this.loggedUser;
      //process date
            let startdate = new Date();
            let startdateyear = startdate.toString().substring(11,15);
            let startdateday = startdate.toString().substring(8,10);
            let startdatemonthWord = startdate.toString().substring(4,7);
            let startmonth = '';
            for (let i in this.months){
                    if(startdatemonthWord.localeCompare(this.months[i].toString())==0){
                            if(i>=9){
                                    let x = parseInt(i)+1;
                                    startmonth = x.toString();
                            }else{
                                    let x = parseInt(i)+1;
                                    startmonth = '0'+x.toString();
                            }
                    }
            }
            //let startdateFormatted = startdateyear+'-'+startmonth+'-'+startdateday; //usable start date 
            //process time
            let timeSelected = startdate.toString().substring(16,21);
            let date = startdateyear+'-'+startmonth+'-'+startdateday+' '+timeSelected+":00"; //usable start date 
            //console.log(date);
      console.log(title,contents,recipients,date,createdBy);
      //send to DB
      let result = await axios.post("https://webber.fenixvida.co.za/submitMessage.php",{title:title,contents:contents,recipients:recipients,date:date,createdBy:createdBy});
      console.log(result);

    },
    async getUsersForSearch(){
      let result = await axios.post("https://webber.fenixvida.co.za/getAllusers.php",{});
      this.allUsers = result.data;
      for(let row in result.data){
        let newArray = result.data[row].split(",");
        this.allUsers[row] = {userID:newArray[0],username:newArray[1],first_name:newArray[2],last_name:newArray[3]};
      }
      this.filteredUsers = this.allUsers;
    },
    updateSearch(event){
      let searchTerm = event.target.value;
      console.log(searchTerm);

      this.filteredUsers = [];
      for (let i in this.allUsers){
          if(this.allUsers[i].first_name.includes(searchTerm)){
               this.filteredUsers.push(this.allUsers[i]);
               //sort array
          }
      }
    },
    async staffRegisterUser(){
        let result = await axios.post("https://webber.fenixvida.co.za/signup.php",{email:this.email,userType:this.userType,firstname:this.firstname,lastname:this.lastname,contact:this.contact,});
        console.log(result);
    },
    async populateAvailableSurveys() {
      this.availableSurveys = [];
      let result = axios.post("https://webber.fenixvida.co.za/getSurveys.php");
      console.log((await result).data);
      (await result).data.forEach((element) => {
        const Earray = element.split(",");
        //let id equal everything after ","
        //let eFname = Earray[1];
        //let Adress equal everything before ","
        let eSname = Earray[0];
        const FullName = eSname;
        this.availableSurveys.push({ survey: FullName });
      });
    },
    surveySelect(event) {
      this.responderSelected = event.target.value;
    },
        async printSurvey() {
      //Full name to Fname and Sname
      const NameArray = this.responderSelected.split(" ");
      let firstname = NameArray[0];
      let lastname = NameArray[1];
      let response = await axios.post("https://webber.fenixvida.co.za/getAnswers.php", {
        firstname: firstname,
        surname: lastname,
      });
      const answerString = JSON.stringify(response.data);
      const JV = JSON.parse(answerString);
      const vOne = JSON.stringify(JV);
      const vTwo = vOne.substring(vOne.indexOf("Answers") + 10);
      const vThree = vTwo.substring(0, vTwo.length - 2);
      const vFour = vThree.replace(/\\/g, "");
      const Fna = JSON.parse(vFour);
      this.dataForTable = Fna;
    },
    async populateUserEmails(){
            this.userEmails = [];
            let result = axios.post("https://webber.fenixvida.co.za/getUserEmails.php",{userType:this.userType},);
            console.log((await result).data);
            (await result).data.forEach(element => {
                    const Earray = element.split(",");
                    //let id equal everything after "," 
                    let eid = Earray[1];
                    //let Adress equal everything before "," 
                    let eAdress =  Earray[0];
                    this.userEmails.push({id:eid,Adress:eAdress});
            });
    },
    async populateBookingTypes(){
            this.bookingTypes = [];
            let result = axios.post("https://webber.fenixvida.co.za/getBookingTypes.php",{userType:this.userType},);
            console.log((await result).data);
            (await result).data.forEach(element => {
                    const Earray = element.split(",");
                    //let id equal everything after "," 
                    let eid = Earray[1];
                    //let Adress equal everything before "," 
                    let eBookingType =  Earray[0];
                    this.bookingTypes.push({id:eid,booking:eBookingType});
            });
    },
    async createBooking(){
            console.log(this.bookingClientSelected, this.bookingTypeSelected, this.bookingDateSelected);
            //process date
            let startdate = this.bookingDatePicker;
            let startdateyear = startdate.toString().substring(11,15);
            let startdateday = startdate.toString().substring(8,10);
            let startdatemonthWord = startdate.toString().substring(4,7);
            let startmonth = '';
            for (let i in this.months){
                    if(startdatemonthWord.localeCompare(this.months[i].toString())==0){
                            if(i>=9){
                                    let x = parseInt(i)+1;
                                    startmonth = x.toString();
                            }else{
                                    let x = parseInt(i)+1;
                                    startmonth = '0'+x.toString();
                            }
                    }
            }
            //let startdateFormatted = startdateyear+'-'+startmonth+'-'+startdateday; //usable start date 
            //process time
            let timeSelected = this.bookingDatePicker.toString().substring(16,21);
            let startdateFormatted = startdateyear+'-'+startmonth+'-'+startdateday+' '+timeSelected+":00"; //usable start date 
            console.log(startdateFormatted);
            //call api
            let result = await axios.post("https://webber.fenixvida.co.za/createBooking.php",{email:this.bookingClientSelected,type:this.bookingTypeSelected,date:startdateFormatted,});
            console.log(result);
    },
    clientSelect(event){
            this.bookingClientSelected = event.target.value;
    },
    bookingSelect(event){
            this.bookingTypeSelected = event.target.value;
    },
    
    yearSelect(event){
            this.bookingYear = event.target.value;
    },
    
    //scheduler methods
    async selectClientBooking(event){
            this.selectedClientbooking = event.target.value;
            this.schedulerBooking = [];
            let result = await axios.post("https://webber.fenixvida.co.za/getClientBooking.php",{BookingID:this.selectedClientbooking},);
                    let responseString = JSON.stringify(result.data);
                    let response = responseString.split(",");
                    let username = response[0].replace(/\[/g, "").replace(/"/g, "");
                    let type = response[1].replace(/[|]|"/gi, '').replace(/"/g, "");
                    let completedActivities = response[2].replace(/\]/g, "").replace(/"/g, "");//String handeling Required?
                    this.schedulerBooking.push({username:username,type:type,activities:completedActivities});
                    //totalActiviesInGivenBookingType - completedActivities = remaining/available Activities
            let resultTwo = await axios.post("https://webber.fenixvida.co.za/getBookingTypeActivities.php",{BookingType:this.schedulerBooking[0].type},);
                    let totalActivities = [];
                    this.remainingActivities = [];
                    let totalActsString = JSON.stringify(resultTwo.data).replace(/"/g,"").substring(1);
                    totalActivities = totalActsString.split("$");
                    //console.log(totalActivities);
                    //console.log(completedActivities);
                    let completedActivitiesArray = completedActivities.substring(1).split("$");
                    for(var i=0; i<totalActivities.length; i++){
                      for(var x=0; x<completedActivitiesArray.length; x++){
                        //alert(totalActivities[i],"vs",completedActivitiesArray[x]);
                        if(totalActivities[i]==completedActivitiesArray[x]){
                          totalActivities[i] = "zzzz";
                          completedActivitiesArray[x] = "xxxx";
                          break;
                        }
                      }
                    }
                    for(var n=0; n<totalActivities.length; n++){
                      if(totalActivities[n]!="zzzz"){
                        this.remainingActivities.push(totalActivities[n]);
                      }
                    }
                    console.log(this.remainingActivities);

    },
    async populateClientBookings(){
            this.schedulerBooking.push({});
            this.clientBookings = [];
            let result = axios.post("https://webber.fenixvida.co.za/getClientBookings.php",{},);
            console.log((await result).data);
            (await result).data.forEach(element => {
                    const Earray = element.split(",");
                    //let id equal everything after "," 
                    let eid = Earray[1];
                    //let Adress equal everything before "," 
                    let eclientBooking =  Earray[0];
                    this.clientBookings.push({id:eid,booking:eclientBooking});
            });

    },
    selectActivity(event){
      this.scheduleActivitySelected = event.target.value;
    },
    async createScheduleEntry(){
      let BookingID = this.selectedClientbooking;
      let AppointmentDate = this.scheduleDate;
      let client = this.schedulerBooking[0].username;
      let Type= this.schedulerBooking[0].type;
      let Activity = this.scheduleActivitySelected;
      console.log(BookingID,client,Type,Activity,AppointmentDate);
      //process date and time for DB
      let AppointmentYear = AppointmentDate.toString().substring(11,15);
      let AppointmentMonth = AppointmentDate.toString().substring(4,7);
      let AppointmentDay = AppointmentDate.toString().substring(8,10);
      console.log(AppointmentYear,AppointmentMonth,AppointmentDay);
      let startmonth = '';
            for (let i in this.months){
                    if(AppointmentMonth.localeCompare(this.months[i].toString())==0){
                            if(i>=9){
                                    let x = parseInt(i)+1;
                                    startmonth = x.toString();
                            }else{
                                    let x = parseInt(i)+1;
                                    startmonth = '0'+x.toString();
                            }
                    }
            }
            let timeSelected = AppointmentDate.toString().substring(16,21);
            let AppointmentDateFormatted = AppointmentYear+'-'+startmonth+'-'+AppointmentDay+' '+timeSelected+":00"; //usable start date 
            console.log(BookingID,AppointmentDateFormatted,client,Type,Activity);
      //push to DB
      let result = await axios.post("https://webber.fenixvida.co.za/createAppointment.php",{id:BookingID,date:AppointmentDateFormatted,client:client,type:Type,activity:Activity},);
      alert(JSON.stringify(result));
    },
    populateSchedule(){
      //get data from schedule table based on date starting on nearest monday?
      //make into object array
      //send to global
    },
    getAvailableActivities(){
      //BookingType Activities - completed Acts of selected clientBooking
      //BookingID gives bookingType and completed acts 
      //BookingType gives BookingType Activities
      //do minus compare
      //write to array
    },
    async CreateBookingType(){
            //get all data from fields
            let BookingTypeName = this.newTypeName;
            let BookingTypePrice = this.BookingPrice;
            let BookingTypeActivitiesUnedited = this.acts;
            let BookingTypeActivities = "";
            for(let i in BookingTypeActivitiesUnedited){
              if(BookingTypeActivitiesUnedited[i].activity!=""&& BookingTypeActivitiesUnedited[i].activity!="New Activity"){
              BookingTypeActivities = BookingTypeActivities +"$" +JSON.stringify(BookingTypeActivitiesUnedited[i].activity).replace(/"/g, '');
            }
            }
            let result = await axios.post("https://webber.fenixvida.co.za/createBookingType.php",{name:BookingTypeName,price:BookingTypePrice,activities:BookingTypeActivities,});
            console.log(result);
    },
    addField(){
            this.acts.push({activity:""});
    },
    removeField(i){
      //remove this.acts at param index
            this.acts.splice(i,1);
    },    
    daySelect(event){
            this.bookingDay = event.target.value;
    },
    monthSelect(event){
            this.bookingMonth = event.target.value;
    },
    async removeBooking(){
            let oldClientEmail = this.originalBooking[0];
            let oldDate = this.originalBooking[1];
            let oldType  = this.originalBooking[2];
            let oldNextDate  = this.originalBooking[4];
            let oldPaymentStatus  = this.originalBooking[3];
            let oldCompleted  = this.originalBooking[6];
            let oldCurrentStep  = this.originalBooking[5];
            console.log(this.originalBooking);
            //call api
            let result = await axios.post("https://webber.fenixvida.co.za/removeBooking.php",{oldEmail:oldClientEmail,oldDate:oldDate,oldType:oldType,oldNextDate:oldNextDate,oldPayment:oldPaymentStatus,oldCompleted:oldCompleted,oldCurrentStep:oldCurrentStep,});
            console.log(result.data);
            alert("booking removed");        
    },
    editButton(rowNumber){
            console.log(rowNumber);
            this.originalBooking = [];
            for(let i=1;i<=7;i++){
                    this.selectedBooking.push(document.querySelector("#sv > table > tbody > tr:nth-child("+rowNumber+") > td:nth-child("+i+")").innerHTML);
                    this.originalBooking.push(document.querySelector("#sv > table > tbody > tr:nth-child("+rowNumber+") > td:nth-child("+i+")").innerHTML);
            }        
            //api gets the selected booking by searching using updated booking and changing values to "selectedBooking"
            //originalbooking = selectedbooking
    },
    async updateBooking(){
            //values to select entry by
            let oldClientEmail = this.originalBooking[0];
            let oldDate = this.originalBooking[1];
            let oldType  = this.originalBooking[2];
            let oldNextDate  = this.originalBooking[4];
            let oldPaymentStatus  = this.originalBooking[3];
            let oldCompleted  = this.originalBooking[6];
            let oldCurrentStep  = this.originalBooking[5];
            //values yo change to 
            let newClientEmail = this.selectedBooking[0];
            let newDate = this.selectedBooking[1];
            let newType  = this.selectedBooking[2];
            let newNextDate  = this.selectedBooking[4];
            let newPaymentStatus  = this.selectedBooking[3];
            let newCompleted  = this.selectedBooking[6];
            let newCurrentStep  = this.selectedBooking[5];
            //call API
            let result = await axios.post("https://webber.fenixvida.co.za/updateBooking.php",{oldEmail:oldClientEmail,oldDate:oldDate,oldType:oldType,oldNextDate:oldNextDate,oldPayment:oldPaymentStatus,oldCompleted:oldCompleted,oldCurrentStep:oldCurrentStep,
            newEmail:newClientEmail,newDate:newDate,newType:newType,newNextDate:newNextDate,newPayment:newPaymentStatus,newCompleted:newCompleted,newCurrentStep:newCurrentStep,});
            if(JSON.stringify(result.data).includes("Updated")){
                        alert("Booking Updated");
                        console.log(result.data); 
            }else{
                        console.log(result.data); 
            }        
            //refresh filter?
            
    },
    async fbButton(){
            //convert dates from Mon Jan 31 2022 19:14:00 GMT+0200 (South Africa Standard Time) to 'yyyy-mm-dd' format
            //index of day = 9 till 10; index of year = 12 till 16 index of month = 5 till 7
            const startdate = this.startdate;
            const enddate = this.enddate;
            //check that start date is earlier than enddate
            let enddateFormatted = "";
            let startdateFormatted = "";
            if(startdate == null || enddate == null){ //if no date
                    enddateFormatted = "";
                    startdateFormatted = "";
            }else{
            let startdateyear = startdate.toString().substring(11,15);
            let startdateday = startdate.toString().substring(8,10);
            let startdatemonthWord = startdate.toString().substring(4,7);
            let startmonth = '';
            for (let i in this.months){
                    if(startdatemonthWord.localeCompare(this.months[i].toString())==0){
                            if(i>=9){
                                    let x = parseInt(i)+1;
                                    startmonth = x.toString();
                            }else{
                                    let x = parseInt(i)+1;
                                    startmonth = '0'+x.toString();
                            }
                    }
            }
            startdateFormatted = startdateyear+'-'+startmonth+'-'+startdateday; //usable start date
            
            let enddateyear = enddate.toString().substring(11,15);
            let enddateday = enddate.toString().substring(8,10);
            let enddatemonthWord = enddate.toString().substring(4,7);
            let endmonth = '';
            for (let i in this.months){
                    if(enddatemonthWord.localeCompare(this.months[i].toString())==0){
                            if(i>=9){
                                    let x = parseInt(i)+1;
                                    endmonth = x.toString();
                            }else{
                                    let x = parseInt(i)+1;
                                    endmonth = '0'+x.toString();
                            }
                    }
            }
            enddateFormatted = enddateyear+'-'+endmonth+'-'+enddateday; //usable end date
            //console.log(startdateFormatted, enddateFormatted,clientSelected,bookingType);
            }
            //client
            const clientSelected = this.bookingClientSelected;
            //bookingtype
            const bookingType = this.bookingTypeSelected;
            //1. date,client,type 2. client,type 3.type 4. date 5. client 6.date,type 7.client,date 8.none
            let queryVal = 0;
            if(bookingType!=""){
                    if(clientSelected!=""){
                            if(startdateFormatted && enddateFormatted !=null){
                                    //{type,client,date}
                                    queryVal = 1;
                                    console.log("type,client,date");
                            }else{
                                    //{type,client}
                                    queryVal = 2;
                                    console.log("type,client");
                            }
                }else if(startdateFormatted && enddateFormatted !=null){
                        //{date,type}
                        queryVal = 3;
                        console.log("type,date");
                }else{
                        //{type}
                        queryVal = 4;
                        console.log("type");
                }
            }else if(clientSelected!=""){
                    if(startdateFormatted && enddateFormatted !=null){
                            //{client,date}
                            queryVal = 5;
                            console.log("client,date");
                    }else{
                            //{client}
                            queryVal = 6;
                            console.log("client");
                    }
            }else if(startdateFormatted && enddateFormatted !=null){
                    //{date}
                    queryVal = 7;
                    console.log("date");
            }else{
                    //no params
                    queryVal = 8;
                    console.log("no params");
            }
            this.bookingData = [];
            let result = await axios.post("https://webber.fenixvida.co.za/showbookings.php",{clientSelected:clientSelected,startdate:startdateFormatted,enddate:enddateFormatted,bookingType:bookingType,queryVal:queryVal,});//get selected data from api
            if(JSON.stringify(result.data).includes("0 results")){
                        alert("No results found"); 
            }else{
                        const asd = JSON.stringify(result.data);
                        this.bookingData = JSON.parse(asd);
            }
           // let result = await axios.post("http://localhost/apig/showbookings.php",);//get selected data from api
           // const asd = JSON.stringify(result.data);
           // this.bookingData = JSON.parse(asd);
    }
    
},
}
</script>

<style>
#editor{
  margin-left: 20px;
  padding-left:20px;
  padding-right:20px;
  display:flexbox;
  border-width: 2px;
  border-style: double;
  border-color: #111;
  height:720px;
  top: 0px;
  position:sticky;
  background-color: #7e7e7e71;

}
#editSchedule{
  height: 100%; /* 100% Full-height */
  width: 35%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 16%;
  background-color: rgba(80, 80, 80, 0.253); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  padding-left: 10px;
}
#scheduleViewer{
  height: 100%; /* 100% Full-height */
  width: 35%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 50%;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  padding-left: 10px;
}
#sv{
  transition: margin-left .5s;
  padding: 10px;
  display:flex;
}
#toolbox{
  transition: margin-left .5s;
  padding: 20px;
}
#toolbox label{
    flex-direction: column;
    display: flex;
    margin-top:15px;
}
#toolboxButton{
  flex-direction: column;
  display: flex;
  margin-left: 340px;
  margin-top: 20px;
}
#leftNav{
  height: 100%; /* 100% Full-height */
  width: 15%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  padding-left: 10px;
}
.nb{
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 90%;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
#filterbar {
  overflow: hidden;
  display: flex;
  border-style: double;
  border-color: #002050;
  background-color: #012c6d96;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 25%;
  top:0;
  position: sticky;
  width: 885px;
  justify-content: space-evenly;
}
#filterstartdate{
  position: relative;
  padding-left:10px;
  padding-right:10px;
  width: 20%;
}
#filterenddate{
  position: relative;
  padding-left:10px;
  padding-right: 20px;
  width: 22%;
}
#filterType{
  margin-left: 10px;
  padding-left:10px;
  padding-right: 20px;
  margin-right: 15px;
  width: 20%;
}
#filterClient{
  margin-left: 10px;
  margin-right: 15px;
  width:20%;
}
#filterButton{
  margin-left: 10px;
  margin-right: 10px;
  padding-left:10px;
  padding-top:9px;
  width: 20%;
  background-color: #016ce6; /* Green */
  border: none;
  color: white;
  text-align:center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4;
  font-weight: bold;
}
#filterButton:hover {
  background-color: #01378a; /* Green */
  color: white;
}
#bookingdatepicker{
  margin-left: 40%;
  width: 20%;
}
table {
  font-family: "Open Sans", sans-serif;
  width: 750px;
  border-collapse: collapse;
  border: 3px solid #44475c;
  margin-left: 25%;
}

table th {
  text-transform: uppercase;
  text-align: left;
  background: #44475c;
  color: #fff;
  padding: 8px;
  min-width: 30px;
}
table td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7d82a8;
}
table td:last-child {
  border-right: none;
}
table tbody tr:nth-child(2n) td {
  background: #d4d8f9;
}
#submitButton{
    flex-direction: column;
    display: flex;
    margin-left: 47%;
    margin-top: 15px;
      background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}
#submitButton {
  transition-duration: 0.4s;
}

#submitButton:hover {
  background-color: #048308; /* Green */
  color: white;
}
#createBookingDate{
    flex-direction: column;
    display: flex;
    margin-left: 47%;
    margin-top: 15px;
    background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}
#createBookingDate {
  transition-duration: 0.4s;
}

#createBookingDate:hover {
  background-color: #048308; /* Green */
  color: white;
}
#removeButton{
  
  margin-left: 5%;
  margin-top: 15px;
  background-color: #f50e0e; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
}
#removeButton:hover {
  background-color: #a00404; /* Green */
  color: white;
}
#updateButton{
     margin-left: 5%;
  margin-top: 15px;
  margin-bottom: 15px;
    background-color: #05c71f; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
}
#updateButton:hover {
  background-color: #188d00; /* Green */
  color: white;
}
#fieldName{
    font-weight: bold;
}
#surveyButton{
  margin-left: 35%;
  margin-top: 15px;
  background-color: #f50e0e; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
}
#surveyButton:hover {
  background-color: #a00404; /* Green */
  color: white;
}
#RemoveActivity{
  background-color: #f50e0e;
  color: white;
  margin-top: 15px;
  font-weight: 900;
  transition-duration: 0.4s;
  text-align: center;
  text-decoration: none;
  border:none;
}
#RemoveActivity:hover {
  background-color: #a00404; /* Green */
  color: white;
}
#AddActivity{
  background-color: #05c71f;
  color: white;
  margin-top: 15px;
  font-weight: 900;
  transition-duration: 0.4s;
  text-align: center;
  text-decoration: none;
  border:none;
}
#AddActivity:hover {
  background-color: #188d00; /* Green */
  color: white;
}
</style>
