<template>
  <div id="app">
 <div id="login">
    <button onclick="location.href='https://fenixvida.co.za'" type="button">back</button>
    <div id="loginContainer">
        <h1>Login</h1>
        <h4>Enter your username and password to continue</h4>
        <label id="usernameLabel">Username</label>
        <input v-model="email" id="usernameField" placeholder="abc@def.com"/>
        <label id="passwordLabel">Password</label>
        <input v-model="password" id="passwordField" placeholder="*****"/>
    <button id="loginButton" v-on:click="login()" class="loginButton">Login</button>
    <button id="signupButton" class="loginButton" >Sign Up</button>
    </div>
  </div>
  </div>
</template>
<script>
import axios from 'axios'
//import {logie} from '@/components/luvar.js'
//import {logie} from '../../../../clientside/src/components/xuvar.js'
export default {
  beforeMount(){
this.clearLoggedCookie();
},
  name: "Login",
  data(){
    return {
    //  logie,
      email:"",
      password: "",
    };
  },
  methods:{
    clearLoggedCookie(){
        document.cookie = "lu=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    async login(){
      axios.defaults.baseURL = '';
       
      let result = await axios.post("https://webber.fenixvida.co.za/tester.php",{email:this.email,password:this.password,});console.log(result.data);
      let auth = result.data;
      if(auth.includes("yes")){
        if (auth.includes("staff")) {
          //this.setLoggedUser();
          document.cookie = encodeURIComponent("lu") + '=' + encodeURIComponent(this.email);
          console.log(document.cookie);
          localStorage.setItem("Type","staff");
          this.$router.push({ path: '/StaffDash' })
      } else {
        document.cookie = encodeURIComponent("lu") + '=' + encodeURIComponent(this.email);
          window.location.href = "https://client.fenixvida.co.za/";//redirect to client
        }
      }
    },
  },
}
</script>
<style>
#loginContainer{
  margin-top: 5% !important;
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
  flex-direction: column;
  align-items:center;
  display:flex;
}
.loginButton{
  margin:10px;
  width: 50%;
  border: 3px solid rgb(142, 189, 15);
  padding: 10px;
}
#passwordField{
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
#passwordLabel{
    margin: auto;
  width: 50%;
  padding: 10px;
}
#usernameLabel{
    margin: auto;
  width: 50%;
  padding: 10px;
}
#usernameField{
    margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
#backButton{
    position: absolute;
  top: 8px;
  left: 16px;
}
</style>