import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import StaffDash from '../views/StaffDash.vue'
import ClientDash from '../views/ClientDash.vue'
import login from '../views/Login.vue'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: login
  },
  {
    path:'/ClientDash',
    name:'ClientDash',
    component: ClientDash
  },
  {
    path:'/StaffDash',
    name:'StaffDash',
    component: StaffDash
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
